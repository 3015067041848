<template>
  <div class="app-container">
    <el-row>
      <el-col :lg="{ span: 18, offset: 3 }">
        <ProfileNavigation v-if="showNavigation" />
        <router-view />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ProfileNavigation from './profile-navigation/ProfileNavigation'

export default {
  name: 'UserProfile',
  components: {ProfileNavigation},
  inject: ['responsive'],
  computed: {
    showNavigation() {
      return !this.responsive.isSm
    }
  }
}
</script>

<style scoped>
</style>
