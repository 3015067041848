<template>
  <el-card>
    <el-menu mode="horizontal" :router="true" :default-active="$route.name">
      <can-i v-for="rt in $options.routes"
             :key="rt.name"
             :permissions="[rt.permission]"
      >
        <el-menu-item

            :route="{ name: rt.name}"
            :index="rt.name"
        >
          {{ $t(`profile_navigation.${rt.name}`) }}
        </el-menu-item>
      </can-i>
    </el-menu>
  </el-card>
</template>

<script>
import {
  PROFILE_ACCOUNT_ROUTE_NAME,
  PROFILE_ORDERS_ROUTE_NAME,
  PROFILE_VOLUNTEER_ROUTE_NAME
} from '../../shared/constants/app-routes-names'

import CanI from '../../auth/can-i/CanI'
import {
  ROLE_VOLUNTEERING_MANAGEMENT,
  ROLE_ACCOUNT_DATA_MANAGEMENT,
  ROLE_PAYMENTS_MANAGEMENT
} from '../../shared/constants/app-permissions'

export default {
  name: 'ProfileNavigation',
  components: {CanI},
  routes: [
    {
      name: PROFILE_ACCOUNT_ROUTE_NAME,
      permission: ROLE_ACCOUNT_DATA_MANAGEMENT
    },
    {
      name: PROFILE_ORDERS_ROUTE_NAME,
      permission: ROLE_PAYMENTS_MANAGEMENT
    },
    {
      name: PROFILE_VOLUNTEER_ROUTE_NAME,
      permission: ROLE_VOLUNTEERING_MANAGEMENT
    }
  ]
}
</script>

<style scoped>

</style>
